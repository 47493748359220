import { createContext, ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { http } from "../index";

interface IUser {
  id: string;
  name: string;
  // Add other properties that exist on the user object
}

interface IAuthContext {
  currentUser: IUser | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<IUser | null>>;
  login: (user: any) => Promise<void>;
  register: (user: any) => Promise<void>;
  logout: () => void;
}

interface IAuth {
  children: ReactNode;
}

export const authContext = createContext<IAuthContext | null>(null);

export function AuthProvider({ children }: IAuth) {
  const lStore: any = localStorage.getItem("user");
  const [currentUser, setCurrentUser] = useState<IUser | null>(JSON.parse(lStore));
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  async function login(user: any) {
    const res = await http.post("/auth/login", user);
    setCurrentUser(res.data.data);
    localStorage.setItem("token", res.data.token);
    navigate("/");
  }

  async function register(user: any) {
    const res = await http.post("/auth/register", user);
    setCurrentUser(res.data.data);
    localStorage.setItem("token", res.data.token);
    navigate("/");
  }

  function logout() {
    setCurrentUser(null);
    localStorage.clear();
    navigate("/");
  }

  return (
    <authContext.Provider value={{ currentUser, setCurrentUser, login, register, logout }}>
      {children}
    </authContext.Provider>
  );
}