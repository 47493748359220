import { useState } from "react";
import { CenteredLoader } from "./Utils";
import { server } from "../../App";
import Label from "../Label";
import Input from "../Input";
import { Link } from "react-router-dom";

export const Contact = () => {
  const [form, setForm] = useState({ name: '', email: '', message: '' });
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [privacyChecked, setPrivacyChecked] = useState(false);

  let handleContactSend = async (e: any) => {
    e.preventDefault();

    if (!privacyChecked) {
      setErrorMessage('Bitte stimmen Sie den Datenschutzbedingungen zu.');
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${server}/sendContact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify(form),
      });

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        await response.json();
        setForm({ name: '', email: '', message: '' });
      } else {
        throw new TypeError("Unzulässiger Inhaltstyp.");
      }
    } catch (error: any) {
      setErrorMessage(error.message);
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handlePrivacyChange = (e: any) => {
    setPrivacyChecked(e.target.checked);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-gray-400">
      <section className="body-font relative bg-gray-900 text-gray-400">

        <div className="container mx-auto px-5 py-24">
          {errorMessage && (
            <div className="mb-8 p-4 bg-red-500 text-white rounded-md">
              <p className="text-lg font-semibold">Oops! Something went wrong.</p>
              <p>{errorMessage}</p>
            </div>
          )}
          <div className="mb-12 flex w-full flex-col text-center">
            <h1 className="text-3xl font-extrabold text-white text-center mb-8">
              Kontaktiere uns
            </h1>            <p className="mx-auto text-base leading-relaxed lg:w-2/3">Wenden dich an uns! Ob du eine Frage hast,
              Feedback oder einen Vorschlag zur Zusammenarbeit hast, wir würden uns freuen, von dir zu hören.
            </p>
          </div>

          <div className="mx-auto md:w-2/3 lg:w-1/2">
            <div className="-m-2 flex flex-wrap">

              <div className="w-1/2 p-2">
                <div className="relative">
                  <Input disabled={isLoading} onChange={handleInputChange} value={form.name} type="text" id="name" name="name" className="peer w-full rounded border border-gray-700 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-8 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder="Name" />
                  <Label className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-indigo-500 transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:bg-gray-900 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-indigo-500">Name</Label>
                </div>
              </div>
              <div className="w-1/2 p-2">
                <div className="relative">
                  <Input disabled={isLoading} onChange={handleInputChange} value={form.email} type="email" id="email" name="email" className="peer w-full rounded border border-gray-700 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-8 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder="Email" />
                  <label htmlFor="email" className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-indigo-500 transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:bg-gray-900 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-indigo-500">Email</label>
                </div>
              </div>
              <div className="mt-4 w-full p-2">
                <div className="relative">
                  <textarea disabled={isLoading} onChange={handleInputChange} value={form.message} id="message" name="message" className="peer h-32 w-full resize-none rounded border border-gray-700 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-6 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder="Nachricht"></textarea>
                  <label htmlFor="message" className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-indigo-500 transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:bg-gray-900 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-indigo-500">Nachricht</label>
                </div>
              </div>
              <div className="w-full p-2">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="privacy"
                    name="privacy"
                    checked={privacyChecked}
                    onChange={handlePrivacyChange}
                    disabled={isLoading}
                    className="mr-2"
                  />
                  <label htmlFor="privacy" className="text-gray-400">Ich habe die <Link to="/privacy-policy" className="text-indigo-500">Datenschutzbedingungen</Link> gelesen und akzeptiere sie.</label>
                </div>
              </div>
              <div className="w-full p-2">
                {isLoading ? (<CenteredLoader />) : (
                  <button disabled={isLoading} onClick={handleContactSend} className="mx-auto flex rounded border-0 bg-indigo-500 py-2 px-8 text-lg text-white hover:bg-indigo-600 focus:outline-none">Senden</button>
                )}
              </div>
            </div>
          </div>

        </div>

      </section>
    </div>
  );
}
