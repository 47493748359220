import { HTMLAttributes, ReactNode } from "react";

interface ILabel extends HTMLAttributes<HTMLLabelElement> {
    className?: string;
    children: ReactNode;
}

const Label = ({className, children, ...rest}: ILabel) => {
    return (
        <label className={`text-gray-200 ${className}`} {...rest}>{children}</label>
    )
}

export default Label;