import axios from "axios";
import { useState, useEffect } from "react";
import { formatNumber } from "../../../components/api/Utils";
import { useAuthProvider } from "../../../hooks/useAuthProvider";
import { server } from "../../../App";

const CPDashboardView = () => {
    const [errorMessage, setErrorMessage] = useState<string>();
    const [data, setData] = useState({ forumId: 0, teamSpeakUid: '', charId: 0, charName: 'Max Mustermann', money: 0, bank: 0, visum: 0 });
    const { currentUser } = useAuthProvider();

    useEffect(() => {
        const load = async () => {
            const response = await axios.get(`${server}/cp/requestChardata`);

            if (response.status !== 200) {
                return;
            }

            const charData = await response.data;
            setData(charData);
        }

        load();
    }, [])

    const save = async () => {
        const request = await axios.post(`/changeChardata`, {
            mode: 'cors',
            body: JSON.stringify({ forum: data.forumId, teamspeak: data.teamSpeakUid })
        });

        if (request.status !== 200) {
            const errorResponse = await request.data;
            setErrorMessage(errorResponse.message);
            return;
        }
    }

    const reloadTeamSpeak = async () => {
        const request = await axios.post(`/realoadTeamSpeak`, {
            mode: 'cors',
            body: JSON.stringify({ uid: data.teamSpeakUid })
        });

        if (request.status !== 200) {
            const errorResponse = await request.data;
            setErrorMessage(errorResponse.message);
            return;
        }
    }

    return (
        <>
            <div className="flex flex-col min-h-screen">
                <main className="flex-1 bg-neutral-800 p-8">
                    <div className="container mx-auto">
                        <div className="max-w-4xl mx-auto bg-neutral-900 rounded-lg shadow-lg p-8">
                            <h2 className="text-2xl font-bold mb-6 text-gray-100">Spieler-Übersicht</h2>
                            <div className="grid grid-cols-2 gap-6">
                                <div className="space-y-4">
                                    <div>
                                        <label htmlFor="forum-id" className="block text-gray-300 font-medium mb-2">
                                            Forum-Id
                                        </label>
                                        <div className="flex items-center border border-gray-600 rounded-md bg-neutral-700/75">
                                            <input
                                                value={data.forumId}
                                                id="forum-id"
                                                className="px-4 py-2 w-full focus:outline-none focus:ring focus:ring-blue-500 bg-neutral-700/75 text-white"
                                                type="number"
                                            />
                                            <div className="relative group">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    className="fill-white mr-2 hover:cursor-pointer"
                                                >
                                                    <path d="m13 7.101.01.001a4.978 4.978 0 0 1 2.526 1.362 5.005 5.005 0 0 1 1.363 2.528 5.061 5.061 0 0 1-.001 2.016 4.976 4.976 0 0 1-1.363 2.527l1.414 1.414a7.014 7.014 0 0 0 1.908-3.54 6.98 6.98 0 0 0 0-2.819 6.957 6.957 0 0 0-1.907-3.539 6.97 6.97 0 0 0-2.223-1.5 6.921 6.921 0 0 0-1.315-.408c-.137-.028-.275-.043-.412-.063V2L9 6l4 4V7.101zm-7.45 7.623c.174.412.392.812.646 1.19.249.37.537.718.854 1.034a7.036 7.036 0 0 0 2.224 1.501c.425.18.868.317 1.315.408.167.034.338.056.508.078v2.944l4-4-4-4v3.03c-.035-.006-.072-.003-.107-.011a4.978 4.978 0 0 1-2.526-1.362 4.994 4.994 0 0 1 .001-7.071L7.051 7.05a7.01 7.01 0 0 0-1.5 2.224A6.974 6.974 0 0 0 5 12a6.997 6.997 0 0 0 .55 2.724z"></path>
                                                </svg>
                                                <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-32 bg-black text-white text-center text-xs rounded-md py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                    Synchronisieren
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="teamspeak-uid" className="block text-gray-300 font-medium mb-2">
                                            TeamSpeakUid
                                        </label>
                                        <div className="flex items-center border border-gray-600 rounded-md bg-neutral-700/75">
                                            <input
                                                value={data.teamSpeakUid}
                                                id="teamspeak-uid"
                                                className="px-4 py-2 w-full focus:outline-none focus:ring focus:ring-blue-500 bg-neutral-700/75 text-white"
                                                type="text"
                                            />
                                            <div className="relative group" onClick={reloadTeamSpeak}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    className="fill-white mr-2 hover:cursor-pointer"
                                                >
                                                    <path d="m13 7.101.01.001a4.978 4.978 0 0 1 2.526 1.362 5.005 5.005 0 0 1 1.363 2.528 5.061 5.061 0 0 1-.001 2.016 4.976 4.976 0 0 1-1.363 2.527l1.414 1.414a7.014 7.014 0 0 0 1.908-3.54 6.98 6.98 0 0 0 0-2.819 6.957 6.957 0 0 0-1.907-3.539 6.97 6.97 0 0 0-2.223-1.5 6.921 6.921 0 0 0-1.315-.408c-.137-.028-.275-.043-.412-.063V2L9 6l4 4V7.101zm-7.45 7.623c.174.412.392.812.646 1.19.249.37.537.718.854 1.034a7.036 7.036 0 0 0 2.224 1.501c.425.18.868.317 1.315.408.167.034.338.056.508.078v2.944l4-4-4-4v3.03c-.035-.006-.072-.003-.107-.011a4.978 4.978 0 0 1-2.526-1.362 4.994 4.994 0 0 1 .001-7.071L7.051 7.05a7.01 7.01 0 0 0-1.5 2.224A6.974 6.974 0 0 0 5 12a6.997 6.997 0 0 0 .55 2.724z"></path>
                                                </svg>
                                                <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-32 bg-black text-white text-center text-xs rounded-md py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                    Synchronisieren
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-4">
                                    <div>
                                        <label htmlFor="char-id" className="block text-gray-300 font-medium mb-2">
                                            Char-Id
                                        </label>
                                        <p className="border border-gray-600 rounded-md px-4 py-2 w-full bg-neutral-700/75 text-white">
                                            {data.charId}
                                        </p>
                                    </div>
                                    <div>
                                        <label htmlFor="char-name" className="block text-gray-300 font-medium mb-2">
                                            Char-Name
                                        </label>
                                        <p className="border border-gray-600 rounded-md px-4 py-2 w-full bg-neutral-700/75 text-white">
                                            {data.charName}
                                        </p>
                                    </div>
                                    <div>
                                        <label htmlFor="money" className="block text-gray-300 font-medium mb-2">
                                            Money
                                        </label>
                                        <p className="border border-gray-600 rounded-md px-4 py-2 w-full bg-neutral-700/75 text-white">
                                            {formatNumber(data.money)}$
                                        </p>
                                    </div>
                                    <div>
                                        <label htmlFor="bankmoney" className="block text-gray-300 font-medium mb-2">
                                            Bankmoney
                                        </label>
                                        <p className="border border-gray-600 rounded-md px-4 py-2 w-full bg-neutral-700/75 text-white">
                                            {formatNumber(data.bank)}$
                                        </p>
                                    </div>
                                    <div>
                                        <label htmlFor="visum" className="block text-gray-300 font-medium mb-2">
                                            Visum
                                        </label>
                                        <p className="border border-gray-600 rounded-md px-4 py-2 w-full bg-neutral-700/75 text-white">
                                            {data.visum}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <button onClick={save} className="m-5 py-3 px-6 text-white text-xl font-bold rounded-lg shadow-lg transform transition-transform duration-150 hover:scale-95" style={{ background: "linear-gradient(90deg, rgb(67, 133, 58), rgb(53, 177, 37))" }}>
                                Speichern
                            </button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default CPDashboardView;