import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

const CPNavBar = () => {
    const [open, setOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState<{ [key: string]: boolean }>({});
    const [permissions, setPermissions] = useState<any>();

    useEffect(() => {
        const load = async () => {
            const response = await axios.get(`/getPermissions`)
            setPermissions(response.data);
        };

        load();
    }, [])

    const isDropdownOpen = (key: string) => {
        return dropdownOpen[key] || false;
    }

    const openDropdown = (key: string) => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [key]: true
        }));
    }

    const toggleDropdown = (key: string) => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    }

    return (
        <>
            <div className="w-full text-gray-700 border-b-[1px] border-gray-200 dark-mode:text-gray-200 dark-mode:bg-gray-800" style={{ background: "linear-gradient(90deg, rgba(22, 22, 22, 0.9), rgba(12, 12, 12, 0.9)", fontFamily: "'Montserrat', sans-serif" }}>
                <div className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
                    <div className="p-4 flex flex-row items-center justify-between">
                        <NavLink to="/cp" className="text-white text-lg font-semibold tracking-widest uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline">Void Roleplay</NavLink>
                        <button className="md:hidden rounded-lg focus:outline-none focus:shadow-outline" onClick={() => setOpen(!open)}>
                            <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                                {!open ? (
                                    <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clip-rule="evenodd"></path>
                                ) : (
                                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                )}
                            </svg>
                        </button>
                    </div>
                    <nav className={`${open ? 'flex' : 'hidden'} flex-col flex-grow pb-4 md:pb-0 md:flex md:justify-end md:flex-row`}>
                        <NavLink to={"/cp"} className={({ isActive, isPending }) => `${isActive ? "bg-gray-200 bg-opacity-60" : ""} text-white link px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg hover:text-black hover:bg-gray-200`} >Übersicht</NavLink>
                        <NavLink to={"/cp/tickets/"} className={({ isActive, isPending }) => `${isActive ? "bg-gray-200 bg-opacity-60" : ""} text-white link px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg hover:text-black hover:bg-gray-200`} >Tickets</NavLink>
                        {permissions && (
                            <>
                            {permissions.ticket && (
                            <>
                                <NavLink to={"/cp/ticket-overview/"} className={({ isActive, isPending }) => `${isActive ? "bg-gray-200 bg-opacity-60" : ""} text-white link px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg hover:text-black hover:bg-gray-200`} >Tickets</NavLink>
                            </>
                        )}
                            </>
                        )}

                        {/*<div className="relative">
                            <button className="flex flex-row items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200"
                                onClick={() => toggleDropdown('other')}>
                                <span>Other</span>
                                <svg fill="currentColor" viewBox="0 0 20 20" className={` ${isDropdownOpen('other') ? 'rotate-180' : 'rotate-0'} inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1`}><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </button>
                            {isDropdownOpen('other') && (
                                <div className="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg md:w-48">
                                    <div className="px-2 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800">
                                        <Link to={"/c/banking/open"} className="block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200">Open bank account</Link>
                                    </div>
                                </div>
                            )}
                        </div>*/}
                    </nav>
                </div>
            </div>
        </>
    );
}


export default CPNavBar;