import { Link } from "react-router-dom";
import "./maintenance.css"

const MaintenanceView = () => {
    return (
        <>
            <div className="flex flex-col justify-center items-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white text-center font-roboto">
                <h1 className="text-4xl font-bold uppercase mb-5 gradient-text from-blue-500 to-blue-900">Void Roleplay V2</h1>
                <p className="text-lg">Wir arbeiten an der neusten Version, mehr Infos auf unserem Discord!</p>
                <p className="text-l mb-10">Solltest du bereits Rechte auf die V2 haben, log dich <Link to={"/auth/login"} className="underline">hier</Link> ein.</p>

                <a href="https://discord.gg/void-roleplay" target="_blank" className="transition-transform duration-300 hover:scale-110">
                    <img src="https://cdn.worldvectorlogo.com/logos/discord-6.svg" alt="Discord Logo" className="w-24 discord-logo hover:rotate-6 hover:scale-110 transition-transform duration-500" />
                </a>

            </div>
        </>
    )
}

export default MaintenanceView;