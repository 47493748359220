import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import GroupIcon from '@mui/icons-material/Group';
import BusinessIcon from '@mui/icons-material/Business';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PublicIcon from '@mui/icons-material/Public';
import ForumIcon from '@mui/icons-material/Forum';
import { server } from "../../../App";
import { formatNumber } from "../../../components/api/Utils";
import { CenteredLoader } from "../../../components/views/Utils";

const   HomeView = () => {
    const [statistics, setStatistics] = useState<any>(null);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const fetchStatistics = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${server}/statistics`);
                const data = await response.json();
                setStatistics(data);
            } catch (error) {
                console.error("Error fetching statistics:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchStatistics();
    }, []);

    return (
        <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
            {isLoading ? (
                <div className="flex items-center justify-center h-screen">
                    <CenteredLoader />
                </div>
            ) : (
                <main>
                    {/* Hero Section */}
                    <section className="bg-backgroundhome first-letter:relative flex items-center justify-center min-h-screen bg-cover bg-center">
                        <div className="absolute inset-0 bg-black bg-opacity-60"></div>
                        <div className="relative z-10 text-center text-white px-6">
                            <h1 className="text-5xl font-extrabold drop-shadow-lg">Willkommen auf Void Roleplay!</h1>
                            <p className="mt-4 text-xl max-w-3xl mx-auto drop-shadow">
                                Entdecke ein einzigartiges Reallife & Roleplay Abenteuer.
                            </p>
                            <Link to="/play" className="mt-8 inline-block px-8 py-4 bg-orange-500 text-white font-semibold rounded-md hover:bg-orange-600 transition-all shadow-md hover:shadow-lg">
                                Jetzt spielen
                            </Link>
                        </div>
                    </section>

                    {/* Statistics Section */}
                    {statistics && (
                        <section className="py-12 bg-gray-900 text-white text-center">
                            <div className="max-w-4xl mx-auto">
                                <p className="text-lg">
                                    Trete unserer lebendigen Minecraft-Community bei und wähle aus <strong className="text-orange-400">{formatNumber(statistics.factionCount)}</strong> spannenden Fraktionen oder werde Teil eines aufstrebenden Unternehmensimperiums mit <strong className="text-orange-400">{formatNumber(statistics.companyCount)}</strong> Unternehmen.
                                </p>
                            </div>
                        </section>
                    )}

                    {/* Features Section */}
                    <section className="py-12 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white">
                        <div className="max-w-5xl mx-auto text-center">
                            <h2 className="text-4xl font-bold mb-8">Warum Void Roleplay?</h2>
                            <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
                                <FeatureCard
                                    icon={<GroupIcon className="text-5xl text-orange-500" />}
                                    title="Aktive Community"
                                    description="Schließe dich hunderten von Spielern an und erlebe ein dynamisches und lebendiges Spielerlebnis."
                                />
                                <FeatureCard
                                    icon={<BusinessIcon className="text-5xl text-orange-500" />}
                                    title="Unternehmen"
                                    description="Gründe und verwalte dein eigenes Unternehmen, kaufe Geschäfte auf, stelle Mitarbeiter an und lass dein Imperium wachsen."
                                />
                                <FeatureCard
                                    icon={<EmojiEventsIcon className="text-5xl text-orange-500" />}
                                    title="Fraktionen"
                                    description={`Tritt einer von ${statistics && statistics.factionCount} Fraktionen bei und erobere, verteitige oder verbesser die Stadt.`}
                                />
                            </div>
                        </div>
                    </section>

                    {/* Community Section */}
                    <section className="py-12 bg-gray-900 text-white text-center">
                        <div className="max-w-4xl mx-auto">
                            <h2 className="text-4xl font-bold mb-8">Unsere Community</h2>
                            <p className="text-lg mb-8">
                                Unsere Community ist das Herz von Void Roleplay. Wir bieten eine freundliche, hilfsbereite und aktive Gemeinschaft von Spielern, die alle dasselbe Ziel teilen: Spaß haben und epische Abenteuer erleben.
                            </p>
                            <div className="flex justify-center space-x-4">
                                <CommunityLink
                                    url="https://discord.gg/void-roleplay"
                                    text="Discord beitreten"
                                    icon={<ForumIcon className="text-xl" />}
                                    bgColor="bg-blue-500"
                                    hoverColor="hover:bg-blue-600"
                                />
                            </div>
                        </div>
                    </section>

                    {/* Global Section */}
                    <section className="py-12 bg-gray-100 dark:text-white dark:bg-gray-800 text-center">
                        <div className="max-w-4xl mx-auto">
                            <h2 className="text-4xl font-bold mb-8">Du und Void Roleplay</h2>
                            <p className="text-lg mb-8">
                                Void Roleplay lebt von seiner Community und so handeln wir. Du hast eine Idee? Sag Sie uns und wir versuchen diese umzusetzen! Dich stört etwas? Sag uns das und wir ändern es!
                            </p>
                        </div>
                    </section>
                </main>
            )}
        </div>
    );
};

const FeatureCard = ({ icon, title, description }: any) => (
    <div className="p-6 bg-white dark:bg-gray-700 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
        <div className="mb-4">{icon}</div>
        <h3 className="text-2xl font-semibold mb-2">{title}</h3>
        <p>{description}</p>
    </div>
);

const CommunityLink = ({ url, text, icon, bgColor, hoverColor }: any) => (
    <Link to={url} className={`px-8 py-4 ${bgColor} text-white font-semibold rounded-md ${hoverColor} transition flex items-center space-x-2 shadow-md hover:shadow-lg`}>
        {icon}
        <span>{text}</span>
    </Link>
);

export default HomeView;
