import { HTMLAttributes, InputHTMLAttributes } from "react";

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
    className?: string;
}

const Input = ({className, ...rest}: IInput) => {
    return (
        <input className={` bg-gray-700 text-white border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 ${className}`} 
            {...rest}
        />
    )
}

export default Input;